import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import { HashRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import LanguageCheck from './Languages/languageCheck'
import './App.css';
import 'typeface-roboto'

import Footer from './footer/footer'
import Header from './header/header'
import NotFound from './pages/notfound'
import { StateProvider }  from './Languages/languageContext'
import Page from './routing/pagetitle'
import {menusections} from './routing/routing'

import backgroundImg from './img/background2.jpg';
 

const useStyles = makeStyles(theme => ({
  scrollDiv: {
    overflowY: 'auto',
    maxHeight: '80vh',
    overflowX: 'hidden',
    height: '80vh',
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    flex: '1 0 auto',
    padding: '20px',
  },
  container: {
    backgroundImage: `url(${backgroundImg})`,
    backgroundColor: theme.palette.background.paper,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100%',
    width: '100%',
    margin: 0,
    maxWidth: 'none',
  }
}));

const App = () => {
  const classes = useStyles();

  return (
    <Router>
      <React.Fragment>
      <CssBaseline />
        <StateProvider>
          <LanguageCheck>
            <Container maxWidth="lg" className={classes.container}>
                  <Header/>
                  <div id='scrollDiv' className={classes.scrollDiv}>
                    <div className={classes.content}>
                      <Switch>
                        <Route exact path="/" render={() => (<Redirect to="/home" />)} />
                        {
                          menusections.map(route => {
                            if(route.subsection) {
                              const mainroute = route;
                              return [
                                <Page exact path={route.link} key={route.link} Component={route.page} section={route.name}/>,
                                route.subsection.map(subRoute => {
                                  return <Page path={mainroute.link.concat(subRoute.link)} key={subRoute.link} Component={subRoute.page} section={mainroute.name} subsection={subRoute.name}/>
                                })
                              ]
                            }
                            else {
                              return <Page path={route.link} key={route.link} Component={route.page} section={route.name}/>
                            }
                          })
                        }
                        <Route component={NotFound} />
                      </Switch>
                    </div>
                    <Footer/>
                  </div>
            </Container>
          </LanguageCheck>
      </StateProvider>
    </React.Fragment>
  </Router>
  );
}

export default App;
