import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import CallOutlinedIcon from '@material-ui/icons/CallOutlined';


const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
    backgroundColor: 'hsla(224, 30%, 5%, 0.72)',
    color: 'white',
    height: '100%',
    flexDirection: 'column',
    [theme.breakpoints.only('sm')]: {
        flexDirection: 'row',
    },
  },
  cardDetails: {
    flex: 1,
  },
  cardMedia: {
    maxHeight: '200px',
    maxWidth: '200px',
    margin: '10px auto',
    [theme.breakpoints.only('sm')]: {
      margin: '0 auto',
    },
  },
  title: {
    fontWeight: '600'
  },
  cardRoot: {
    paddingBottom: '0',
    '&:last-child': {
      paddingBottom: '0'
    }
  },
  link: {
    color: 'white',
    textDecoration: 'none',
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    "&:hover" : {
      textDecoration: 'underline'
    }
  },
  linkIcon: {
    marginRight: '5px'
  }
}));


export default function Person({person, ...props}) {
  const classes = useStyles(props);

  return (
    <Card className={classes.card}>
        <div className={classes.cardDetails}>
            <CardContent className={classes.cardRoot}>
            <Typography component="h2" variant="h5" className={classes.totem}>
                {person.totem}
            </Typography>
            <Typography variant="subtitle1">
                {person.name}
            </Typography>
            {
              person.email && (
                <Typography variant="subtitle1" color="primary">
                  <a className={classes.link} href = {"mailto:" + person.email} ><MailOutlineIcon className={classes.linkIcon}/><span>{person.email}</span></a>
                    
                </Typography>
              )
            }
            
            <Typography variant="subtitle1" color="primary">
              <a className={classes.link} href={person.website} rel="noopener noreferrer" target="blank">{person.website}</a>
                
            </Typography>
            { person.phoneNumber && (
              <Typography variant="subtitle1" paragraph>
                <a className={classes.link} href={"tel:" + person.phoneNumber}><CallOutlinedIcon className={classes.linkIcon}/><span>{person.phoneNumber}</span></a>
              </Typography>
            )}
            </CardContent>
        </div>
        <CardMedia
        className={classes.cardMedia}
        component={'img'}
        image={person.photo.url}
        title="Image title"
        />
    </Card>
  );
}
