const data = 
    {
        'Nederlands': [
            {  
                'title': 'Algemene website update',
                'extra': 'Gehele website is vernieuwd',
                'link': ''
            },
        ],
        'Français': [
            {  
                'title': 'Nouveau site',
                'extra': 'Update générale du site',
                'link': ''
            },
        ]
    }

  export default data