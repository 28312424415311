import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({

    toolbarSecondary: {
      justifyContent: 'space-between',
      display: 'flex',
      margin: 10,
      backgroundColor: 'rgba(1,1,1,0)'
    },
    toolbarLink: {
      padding: theme.spacing(1),
      width: '10vw',
      display: 'block',
      flexGrow: 1,
      textAlign: 'center',
      marginLeft: '10px',
      marginTop: '10px',
      marginRight: '10px',
      color: 'white',
      textDecoration: 'none',
      backgroundColor: 'hsla(224, 30%, 5%, 0.72)',
      transition: 'all 0.5s ease',
      fontWeight: '600',
      cursor: 'pointer',
      '&:hover': {
          backgroundColor: 'hsl(159, 77%, 44%)',
          fontWeight: '800', 
        }
    },
    toolbarSubLink: {
      padding: theme.spacing(1),
      width: '100%',
      display: 'block',
      flexGrow: 1,
      textAlign: 'center',
      marginLeft: 'auto',
      paddingTop: '10px',
      marginRight: 'auto',
      color: 'white',
      fontWeight: '600',
      textDecoration: 'none',
      '&:hover': {
          backgroundColor: 'hsl(159, 77%, 44%)',
          color: 'white',
          fontWeight: '800', 
        }
    },
    activeToolbarLink: {
      backgroundColor: 'hsla(159, 77%, 44%, 0.5)',
      fontWeight: '800', 
    },
    li: {
      listStyle: 'none'
    },
    ul: {
      padding: 0
    },
    subMenu: {
      position: 'absolute',
      marginLeft: '10px',
      marginRight: '10px',
      backgroundColor: 'hsla(0, 0%, 0%, 0.85)',
      zIndex: 10,
      width: '10vw'
    },
    subSmallMenu: {
      backgroundColor: 'hsla(0, 0%, 0%, 0.7)',
    },
    menuDiv: {
        width: '20%'
    },
    iconDiv: {
      marginLeft: '26px',
      marginRight: '26px',
      marginBottom: '10px',
      display: 'flex',
      alignItems: 'center',
      height: '36px',
      '&:hover': {
        backgroundColor: 'hsl(159, 77%, 44%)',
        color: 'white'
      }
    },
    icon: {
      margin: '0 auto',
    }
  }));