import React, {useState} from 'react';
import Collapse from '@material-ui/core/Collapse'
import { HashRouter as router, NavLink, withRouter } from "react-router-dom";
import useStyles from './styling'
import {useLangValue }  from '../Languages/languageContext'


export default function CustomizedMenus(props) {
  const [{ languagePackage }] = useLangValue();
  const [menuShow, setMenuShow] = useState(false)
  
  const classes = useStyles();

  const handleHover = () => {
    setMenuShow(prev => !prev)
  }


  return (
    <div onMouseEnter={handleHover} onMouseLeave={handleHover} className={classes.menuDiv}>
        <li className={classes.li}>
            
          <NavLink
          to={props.menusection.link}
          className={classes.toolbarLink}
          activeClassName={classes.activeToolbarLink}
        >
          {languagePackage.default.nav[props.menusection.name]}
        </NavLink>
        {
          props.menusection.subsection && (
            <Collapse in={menuShow} timeout={500} className={classes.subMenu}>
                <ul className={classes.ul}>
                {props.menusection.subsection.map(section => (
                  <li className={classes.li} key={section.name}>
                    <NavLink
                      to={props.menusection.link + section.link}
                      key={section.name}
                      className={classes.toolbarSubLink}
                    >
                      {languagePackage.default.nav[section.name]}
                    </NavLink>
                  </li>
                  ))}
                </ul>
            </Collapse>
          )
        }
        
        </li>
    </div>


  );
}