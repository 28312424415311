import dinnerparents from '../../img/albums/2017/dinnerparents.jpg'
import dinnerparentsGif from '../../img/albums/2017/dinnerparents.gif'


export default function data(languagePackage) {
    return [
      {
        year: '2017',
        photos:
          [{
            title: languagePackage.default.photos.dinnerparents,
            link: 'https://photos.app.goo.gl/zN6QTwusV48H9rod2',
            media: dinnerparents,
            gif: dinnerparentsGif,
          },
        ]
      },
  ];
}