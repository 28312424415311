exports = module.exports = require("../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("./assets/fonts/opensans/OpenSans-Regular.ttf"));
var ___CSS_LOADER_URL___1___ = urlEscape(require("./assets/fonts/opensans/OpenSans-Bold.ttf"));
var ___CSS_LOADER_URL___2___ = urlEscape(require("./assets/fonts/opensans/OpenSans-ExtraBold.ttf"));

// Module
exports.push([module.id, "@font-face {\n  font-family: 'open sans';\n  src: url(" + ___CSS_LOADER_URL___0___ + ");\n  font-weight: 400;\n}\n\n@font-face {\n  font-family: 'open sans';\n  src: url(" + ___CSS_LOADER_URL___1___ + ");\n  font-weight: 600;\n}\n\n@font-face {\n  font-family: 'open sans';\n  src: url(" + ___CSS_LOADER_URL___2___ + ");\n  font-weight: 800;\n}\n\n\n.App {\n  text-align: center;\n}\n* {\n  font-family: 'open sans' !important;\n}\n\n\nbody {\n  background-color: black;\n  height: 100%;\n  width: 100%;\n  overflow: hidden;\n}\n::-webkit-scrollbar {\n  width: 0px;  \n  background: transparent; \n}\n\nhtml {\n  height: 100%;\n  width: 100%;\n  background-color: black;\n}\n#root {\n  height: 100%;\n  width: 100%;\n}", ""]);

