import React, { useEffect } from 'react'
import { useLangValue } from '../Languages/languageContext';

function useTitle(subsection, section) {
    const [{ languagePackage }] = useLangValue();
    useEffect(
        () => {
            document.title =  subsection ? '49 Corbisier - ' + languagePackage.default.nav[section] + ' - ' + languagePackage.default.nav[subsection] : '49 Corbisier - ' + languagePackage.default.nav[section]
            document.getElementById("scrollDiv").scrollTo(0, 0)
        }
    );
}

export default function Page({Component, subsection, section}) {
    useTitle(subsection, section)
    return <Component section={section}/>
}