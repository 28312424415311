import chansonnier from '../../documents/chansonnier.pdf'
import program from '../../documents/programScouts.pdf'

export default function data(languagePackage) {
    return {
            infoText: languagePackage.default.docs.infoTextScouts,
            docs: [
                {
                file: chansonnier,
                title: languagePackage.default.docs.chansonnier,
                },
                {
                file: program,
                title: languagePackage.default.docs.program,
                },
            ]
        }
  }