import React, {useState} from 'react';
import {useLangValue, languages } from './languageContext'
import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';

const useStyles = makeStyles(theme => ({
  langDiv: {
    color: 'white',
  },
  langImg: {
    marginBottom: '-6px',
    paddingRight: '5px',
  },
  subMenu: {
    position: 'absolute',
    right: 10,
    backgroundColor: 'rgba(256,256,256,0.85)',
    zIndex: 10,
  },
  li: {
    listStyle: 'none',
    cursor: 'pointer',
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingTop: '3px',
    paddingBottom: '3px',
    color: 'rgba(0, 0, 0, 0.87)',

    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.3)'
    }
  },
  ul: {
    padding: 0,
  },
}));

export default function LanguageSwitcher() {
  const [{lang}, dispatch] = useLangValue();
  const classes = useStyles();
  const [langShow, setLangShow] = useState(false)
  return (
    <div onMouseOver={() => setLangShow(true)} onMouseLeave={() => setLangShow(false)}>
      {languages.map(langs => (
        lang === langs.name && (
        <div className={classes.langDiv} key={langs.name}>
          <img src={langs.icon} alt={langs.name} className={classes.langImg}/>
          {langs.name}
        </div>
        )
      ))}
      <Collapse in={langShow} timeout={600} className={classes.subMenu}>
                <ul className={classes.ul}>
                {languages.map(lang => (
                  <li className={classes.li} key={lang.name} onClick={() => {setLangShow(false); dispatch({newLang:lang.name})}}>
                    <img src={lang.icon} alt={lang.name} key={lang.name} className={classes.langImg}/>
                    {lang.name}
                  </li>
                  ))}
                </ul>
            </Collapse>
    </div>
  );
}
