import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Hidden from '@material-ui/core/Hidden';
import defaultImage from '../img/color.png'

const useStyles = makeStyles(theme => ({
  card: {
    width: '100%',
    margin: '0 auto',
    display: 'flex',
    backgroundColor: 'hsla(224, 30%, 5%, 0.72)',
    color: 'white',
    height: '100%',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        textAlign: 'center'
    },
  },
  cardDetails: {
    flex: 1,
  },
  cardMedia: {
    maxWidth: '200px',
    margin: 'auto',
    maxHeight: '200px',
    objectFit: 'contain',
    [theme.breakpoints.down('sm')]: {
      margin: '10px auto',
    },
  },
  title: {
    fontWeight: '600',
    color: 'hsla(159, 77%, 44%, 0.5)'
  },
  cardRoot: {
    paddingBottom: '0',
    '&:last-child': {
      paddingBottom: '0'
    }
  },
  adressText: {
    color: 'hsla(230, 20%, 85%, 1)'
  }
}));


export default function Reunion({reunion, even, noLink, ...props}) {
  const classes = useStyles(props);

  return (
        <Card className={classes.card}>
          
          <Hidden smDown>
              { 
                  even
                  &&
                  <CardMedia
                      className={classes.cardMedia}
                      component={'img'}
                      image={reunion.picture?.url ? reunion.picture.url : defaultImage}
                      title="Image title"
                  />
              }
            </Hidden>
            <div className={classes.cardDetails}>
                <CardContent className={classes.cardRoot}>
                <Typography component="h2" variant="h5" className={classes.title}>
                    {reunion.name}
                </Typography>
                <Hidden mdUp>
                  <CardMedia
                      className={classes.cardMedia}
                      component={'img'}
                      image={reunion.picture ? reunion.picture.url : defaultImage}
                      title="Image title"
                  />
                </Hidden>
                <Typography variant="subtitle1">
                    {new Date(reunion.date).toDateString()} - {new Date(reunion.date).toLocaleTimeString('nl-be')}
                </Typography>
                <Typography variant="subtitle1">
                {
                  noLink ?
                    <p className={classes.adressText}>{reunion.adress}</p>
                  :
                    <a className={classes.adressText} href={"http://maps.google.com/?q=" + reunion.adress} rel="noopener noreferrer" target="blank">{reunion.adress}</a>
                }
                
                </Typography>
                <Typography variant="subtitle1" className={classes.adressText}>
                    {reunion.addition}
                </Typography>
                </CardContent>
            </div>
            
            <Hidden smDown>
              { 
                  !even
                  &&
                  <CardMedia
                      className={classes.cardMedia}
                      component={'img'}
                      image={reunion.picture?.url ? reunion.picture.url : defaultImage}
                      title="Image title"
                  />
              }
            </Hidden>
        </Card>
    
  );
}
