import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { HashRouter as router, NavLink, withRouter } from "react-router-dom";

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import logo from '../img/Logo_Website.png'

import SmallMenuItems from './smallMenuItems'
import LanguageSwitcher from '../Languages/languageswitcher'

const useStyles = makeStyles(theme => ({

  fullList: {
    width: 'auto',
  },
  title: {
      flexGrow: 1,
      textAlign: 'center'
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  logo: {
    width: '23vw',
    marginTop: '10px'
  },
  appBar: {
    backgroundColor: 'rgba(0, 0, 0, 0.50)'
  }
}));

export default function SmallMenu(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setOpen(open);
  };


  return (
    <div>
       <AppBar
        position="fixed"
        className={classes.appBar}
      >
        <Toolbar>
        <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer(true)}
            edge="start"
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap className={classes.title}>
            <NavLink to={`/home`}>
                <img src={logo} alt={'49 logo'} className={classes.logo}/>
            </NavLink>
          </Typography>
          <LanguageSwitcher/>
        </Toolbar>
      </AppBar>
     
      <SwipeableDrawer
        anchor="top"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <SmallMenuItems toggleDrawer={toggleDrawer}/>
      </SwipeableDrawer>
    </div>
  );
}