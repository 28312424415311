import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {useLangValue }  from '../../Languages/languageContext'
import updateData from '../../data/updateData'
import UpdateList from './updatelist'
import FirstReunion from './firstReunions'


const useStyles = makeStyles(theme => ({
    card: {
        display: 'flex',
        backgroundColor: 'hsla(224, 30%, 5%, 0.72)',
        color: 'white',
      },
      cardDetails: {
        flex: 1,
        whiteSpace: 'pre-line',
      },
      cardMedia: {
        width: 160,
      },
      markdown: {
        ...theme.typography.body2,
        padding: theme.spacing(3, 0),
      },
      sidebarAboutBox: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.grey[200],
      },
      sidebarSection: {
        marginTop: theme.spacing(3),
      },
      iframe: {
          width: '100%',
          maxWidth: '800px',
          height: '60vh',
          maxHeight: '450px',
          margin: '0 auto'
      },
      cardContent: {
        display: 'flex',
        flexDirection: 'column'
      }
}));


export default function Contact() {
  const classes = useStyles();
  const [{ lang, languagePackage }] = useLangValue();

  return (
        <main className={classes.main}>
          <Grid container spacing={4} className={classes.cardGrid}>
            <Grid item xs={12} md={3}>
                  <Card className={classes.card}>
                    <div className={classes.cardDetails}>
                      <CardContent>
                        <Typography component="h2" variant="h5">
                          Site updates
                        </Typography>
                        <UpdateList updates={updateData[lang]}/>
                      </CardContent>
                    </div>
                  </Card>
              </Grid>
              <Grid item xs={12} md={9}>
                  <Card className={classes.card} style={{marginBottom: '30px'}}>
                    <div className={classes.cardDetails}>
                      <CardContent className={classes.cardContent}>
                        <Typography component="h2" variant="h5">
                          Just Scout it
                        </Typography>
                        <Typography variant="subtitle1" paragraph>
                            {languagePackage.default.home.about}
                        </Typography>
                        </CardContent>
                    </div>
                  </Card>
                  <Card className={classes.card}>
                    <div className={classes.cardDetails}>
                      <CardContent className={classes.cardContent}>
                        <Typography component="h2" variant="h5">
                          {languagePackage.default.home.firstReunion}
                        </Typography>
                        <FirstReunion/>
                      </CardContent>
                    </div>
                  </Card>
              </Grid>
              
          </Grid>
        </main>
  );
}
