import cafe49 from '../../img/albums/cafe/cafe49.jpg'
import cafe49Gif from '../../img/albums/cafe/cafe49.gif'
import therenaissance from '../../img/albums/cafe/therenaissance.jpg'
import therenaissanceGif from '../../img/albums/cafe/therenaissance.gif'
export default function data(languagePackage) {
    return [{
            title: languagePackage.default.cafe.cafe49,
            link: 'https://photos.app.goo.gl/QFWi9L5r61h9FD9w8',
            media: cafe49,
            gif: cafe49Gif,
          },      {
            title: languagePackage.default.cafe.therenaissance,
            link: 'https://photos.app.goo.gl/ttf2WQWU51ezUeJ39',
            media: therenaissance,
            gif: therenaissanceGif,
          }
    ];
}