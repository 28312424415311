import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import bigLogo from '../img/Scouts_Logo.png'
import LanguageSwitcher from '../Languages/languageswitcher'
import {useLangValue }  from '../Languages/languageContext'
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';

import { HashRouter as router, NavLink, withRouter } from "react-router-dom";
import * as routes from '../routing/routing'
import Menu from './menu'
import SmallMenu from './smallMenu'

const useStyles = makeStyles(theme => ({
  headerDiv: {
    height: '20vh',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      minHeight: '100px'
    },
    marginBottom: '16px'
  },
  toolbar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbarTitle: {
    flex: 1,
  },
  toolbarSecondary: {
    justifyContent: 'space-between',
    display: 'flex',
  },
  logo: {
    width: '23vw',
    marginTop: '10px'
  },
  bigLogo: {
    height: '13.223vw',
    maxHeight: '18vh',
    marginTop: '20px'
  },
  bigLogoDiv: {
    textAlign: 'center'
  },
  title: {
    color: 'white',
    textShadow: '2px 2px 6px black'
  }
}));

 
function Header(props) {
  const [{ lang, languagePackage }] = useLangValue();
  const classes = useStyles();

  const getTitle = (props) => {
    const splits = props.location.pathname.split("/")
    let title = ""
    splits.forEach(element => {
      if(element !== '') {
        title = languagePackage.default.nav[element] + ' ' + title
      }
    });
    return title
    
  }

  return (
      <div className={classes.headerDiv}>
        
        <Grid container spacing={2}>
          <Grid item md={2} className={classes.bigLogoDiv}>
          <NavLink to={`/home`}>
            <Hidden smDown>
              <img src={bigLogo} alt={'49 logo'} className={classes.bigLogo}/>
            </Hidden>
          </NavLink>
          </Grid>
          <Grid item xs={12} sm={12} md={10}>
            
            <Hidden mdUp>
              <SmallMenu></SmallMenu>
            </Hidden>
            <Hidden smDown>
              <Toolbar className={classes.toolbar}>
                  <div
                  className={classes.toolbarTitle}
                  >
                    <h1 className={classes.title}>{getTitle(props)}</h1>
                  </div>
                  <LanguageSwitcher/>
              </Toolbar>
            </Hidden>
            <Hidden smDown>
              <Toolbar component="nav"className={classes.toolbarSecondary}>
                {
                  routes.menusections.map((section, i) => {
                    return <Menu key={i} menusection={section}/>
                  })
                }
              </Toolbar>
            </Hidden>
          </Grid>
        </Grid>
        

      </div>
  );
}


export default withRouter(Header);
