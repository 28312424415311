import React, { useState } from 'react';
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles';

import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';

const useStyles = makeStyles(theme => ({
    image: {
        width: '20vw',
        [theme.breakpoints.down('md')]: {
            width: '30vw',
        },
        [theme.breakpoints.down('sm')]: {
            width: '60vw',
        },
        height: '100%',
        border: 'solid hsla(224, 15%, 28%, 1) 2px',
        '&:hover': {
            border: 'solid hsla(224, 20%, 70%, 1) 3px',
        }

    }
  }));

export default function GridListPhotoTile({photo, ...props}) {
    const [hover, setHover] = useState();
    const classes = useStyles(props);
   
    return (
        <GridListTile
            key={photo.title}
            component={Link}
            href={photo.link}
            rel={"noopener noreferrer"}
            target={"blank"}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            <img src={(hover && photo.gif) ? photo.gif : photo.media } alt={photo.title} className={classes.image}/>
            <GridListTileBar
                title={photo.title}
            />
        </GridListTile>
    );
}
