import React, {createContext, useContext, useReducer} from 'react'
import * as dutch from '../Languages/nl'
import * as french from '../Languages/fr'

import dutchFlag from '../img/nl.png'
import frenchFlag from '../img/fr.png'

export const languageContext = createContext();

export const languages = [{name: 'Nederlands', icon: dutchFlag},{name: 'Français', icon: frenchFlag}]


const initialState = {
    lang: null,
    languagePackage: null,
    value: null
  };
  
const reducer = (state, action) => {
    switch (action.newLang) {
        case languages[0].name:
            localStorage.setItem('defaultLanguage', action.newLang);
          return {
              ...state,
              lang: action.newLang,
              languagePackage: dutch,
              value: "nl_BE"
          };
        case languages[1].name:
            localStorage.setItem('defaultLanguage', action.newLang);
            return {
                ...state,
                lang: action.newLang,
                languagePackage: french,
                value: "fr_BE"
            }
        default:
        return state;
    }
};


export const StateProvider = ({children}) =>(
  <languageContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </languageContext.Provider>
);
export const useLangValue = () => useContext(languageContext);

