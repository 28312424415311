import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import bigLogo from '../img/Scouts_Logo.png'
import { Button } from '@material-ui/core';
import {useLangValue, languages } from '../Languages/languageContext'
import Grid from '@material-ui/core/Grid';



const useStyles = makeStyles({
    main: {
        backgroundColor: 'hsla(224, 15%, 28%, 1)',
        height: '100%',
        width: '100%',
        textAlign: 'center'
    },
    bigLogo:{
        marginTop: '20px',
        height: '50vh'
    },
    buttonDiv: {
        display: 'flex',
        justifyContent: 'space-evenly'
    },
    button: {
        border: 'solid white 5px',
        padding: '0 1% 0 1%',
        borderRadius: 0,
        color: 'white',
        textTransform: 'none',
        width: '250px',
        '&:hover': {
            color: 'hsla(224, 15%, 28%, 1)',
            backgroundColor: 'white'
          }
    },
});


export default function LanguagePage() {
    const classes = useStyles();
    const [, dispatch] = useLangValue();

    return (
            <main className={classes.main}>
                <div>
                    <img src={bigLogo} alt={'49 logo'} className={classes.bigLogo}/>
                </div>
                <div className={classes.buttonDiv}>
                    
                    <Grid container spacing={4} className={classes.cardGrid}>
                    {languages.map(lang => (
                        <Grid item key={lang.name} xs={12} md={6}>
                        <Button className={classes.button} onClick={() => dispatch({newLang:lang.name})}>
                            <h2>{lang.name}</h2>
                        </Button>
                    </Grid>
                    ))}
                    </Grid>
                </div>
            </main>
    );
}
