import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import ListSubheader from '@material-ui/core/ListSubheader';
import GridListPhotoTile from './gridListPhotoTile'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import loups from './photos_loups'
import scouts from './photos_scouts'
import unite from './photos_unite'
import { useLangValue } from '../../Languages/languageContext';


const useStyles = makeStyles(theme => ({
  main: {
    display: 'flex'
  },
  listSubheader: {
    height: 'auto',
    backgroundColor: 'hsla(224, 15%, 28%, 0.5)',
    marginTop: '20px'
  },
  gridList: {
    width: '60vw',
    maxWidth: '60vw',
    margin: 'auto',
    overflow: 'auto',
    maxHeight: '65vh',
  },
  listSubHeaderText: {
    color: 'white',
    height: 'auto',
  },
  years: {
    backgroundColor: 'hsla(224, 15%, 28%, 0.5)',
    color: 'white',
    marginTop: '20px',
    marginBottom: 'auto'
  },
  yearItem: {
    '&:hover': {
      backgroundColor: 'hsl(159, 77%, 44%)',
      color: 'white'
    },
  },
  selected: {
    backgroundColor: 'hsla(159, 77%, 44%, 0.5) !important',
    color: 'white'
  }
}));

export default function Photo({section}) {
    const classes = useStyles();
    const [{ languagePackage }] = useLangValue();
    let newData
    switch (section) {
        case 'loups':
            newData = loups
            break;
        case 'scouts':
            newData = scouts
            break;
        case 'unite':
            newData = unite
            break
        default:
          break
    }
    const photoData = newData(languagePackage);
    
    const [selectedYear, setSelectedYear] = useState(photoData[0].year);

    const handleScroll = () => {
      const headerSize = photoData[0].currentYear.offsetParent.parentNode.parentNode.getBoundingClientRect().top + 20
      const index = photoData.findIndex(photo => photo.currentYear.getBoundingClientRect().top - headerSize > 0)
      setSelectedYear(photoData[index===0 ? 0 : index-1].year)
    }
    
    const scrollToRef = (ref) => {setSelectedYear(ref.textContent); ref.scrollIntoView({behavior: 'smooth', block: 'start'})} 

  
    return (
            <main className={classes.main}>
              <List component="nav" className={classes.years}>
              {photoData.map(years => {
                     return (<ListItem
                              key={years.year}
                              button
                              className={classes.yearItem}
                              selected={selectedYear === years.year}
                              classes={{selected:  classes.selected}}
                              onClick={() => scrollToRef(years.currentYear)}
                            >
                              <ListItemText primary={years.year} />
                            </ListItem>)
                            })
                  }
              </List>
              <GridList cellHeight={'auto'} className={classes.gridList} style={{ margin: 'auto'}} cols={1} onScroll={() => handleScroll()}>
                {
                  photoData.map(years => {
                    return [
                    <GridListTile key={years.year} cols={1} style={{ height: 'auto', width: '100%'}} className={classes.listSubheader}>
                      <ListSubheader ref={(ref) => years.currentYear = ref} component="div" className={classes.listSubHeaderText}>{years.year}</ListSubheader>
                    </GridListTile>,
                    years.photos.map(photo => (
                        <GridListPhotoTile photo={photo}/>
                        )
                      )
                    ]
                  })
                }
                </GridList>

                
            </main>
    );
}
