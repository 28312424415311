import media from '../../img/albums/2019/reunionparents.png'

import programScouts from "../../documents/programScouts.pdf"


//new Date(year : Number, month : Number, [date = 1 : Number, [hours = 0 : Number, [minutes = 0 : Number]])
// month starts at 0
export default function data(languagePackage) {
  const local = 'Varenlaan 9 - 2020 Antwerpen'
    return {
      program: programScouts,
      fileName: languagePackage.default.reunionsScout.programScout,
      reunions: [
      
      {
        reunion: languagePackage.default.reunionsScout.firstReunion,
        date: new Date(2019,9,6,9,45),
        //new Date(year : Number, month : Number, [date = 1 : Number, [hours = 0 : Number, [minutes = 0 : Number]])
        // month starts at 0
        location: local,
      },
      {
        reunion: languagePackage.default.reunionsScout.secondReunion,
        date: new Date(2019,9,13,9,45),
        location: 'Station Antwerpen-Berchem',
      },
      {
        reunion: languagePackage.default.reunionsScout.thirdReunion,
        date: new Date(2019,9,20,9,45),
        location: local,
      },
      {
        reunion: languagePackage.default.reunionsScout.fifthReunion,
        date: new Date(2019,10,10,9,45),
        location: 'Kattenbroek 33 - 2650 Edegem',
      },
      {
        reunion: languagePackage.default.reunionsScout.fourthReunion,
        date: new Date(2019,10,17,9,45),
        location: local,
      },
      {
        reunion: languagePackage.default.reunionsScout.sixthReunion,
        date: new Date(2019,10,24,9,45),
        location: 'IJsstadion Antarctica Moerelei 119-121 - Wilrijk',
      },
    ]
  }
}