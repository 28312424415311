import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {useLangValue }  from '../../Languages/languageContext'


const useStyles = makeStyles(theme => ({
    card: {
        display: 'flex',
        backgroundColor: 'rgba(0, 0, 0, 0.70)',
        color: 'white'
      },
      cardDetails: {
        flex: 1,
        whiteSpace: 'pre-line',
      },
      cardMedia: {
        width: 160,
      },
      markdown: {
        ...theme.typography.body2,
        padding: theme.spacing(3, 0),
      },
      cardContent: {
        display: 'flex',
        flexDirection: 'column'
      }
}));


export default function Contact() {
  const classes = useStyles();
  const [{ languagePackage }] = useLangValue();

  return (
        <main className={classes.main}>
          <Grid container spacing={4} className={classes.cardGrid}>
              <Grid item xs={12} md={12}>
                  <Card className={classes.card}>
                    <div className={classes.cardDetails}>
                      <CardContent className={classes.cardContent}>
                      <Typography component="h2" variant="h5">
                          {languagePackage.default.history.pagename}
                      </Typography>
                       
                        <Typography variant="subtitle1" paragraph>
                            {languagePackage.default.history.part0}
                            {languagePackage.default.history.part1}
                            {languagePackage.default.history.part2}
                            {languagePackage.default.history.part3}
                            {languagePackage.default.history.part4}
                            {languagePackage.default.history.part5}
                            {languagePackage.default.history.part6}
                            {languagePackage.default.history.part7}
                            {languagePackage.default.history.part8}
                            {languagePackage.default.history.part9}
                            

                        </Typography>
                      </CardContent>
                    </div>
                  </Card>
              </Grid>
              
          </Grid>
        </main>
  );
}
