import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined';

const useStyles = makeStyles(theme => ({

      tableRow: {
        cursor: "pointer",
        '&:hover': {
          textDecoration: 'underline'
        }
      },
      tableLink: {
        textDecoration: 'none',
        color: 'white'
      },
      tableCell: {
        color: 'white',
        borderBottom: '1px #ffffff80 dotted',
      },
      flexTableCell: {
        display: 'flex',
        flexDirection: 'column'
      },
      iframe: {
        marginTop: '20px',
        height: '500px'
      }
}));


export default function CustomnTableRow({doc}) {
  const classes = useStyles();
  const [showIframe, setShowIframe] = useState(true);


    const handleClick = () => {
      setShowIframe(!showIframe)
    }

  return (
    <TableRow className={classes.tableRow} onClick={handleClick}>
        <TableCell className={classes.tableCell} component='th'>
          <div className={classes.flexTableCell}>
            {doc.title}
            <iframe hidden={showIframe} src={doc.file} title={doc.title} className={classes.iframe}>
                {/* Presss me: <a href="../../documents/programLoups.pdf">Download PDF</a> */}
            </iframe>
          </div>
          
        </TableCell>
        <TableCell className={classes.tableCell} align='right'>
          <a href={doc.file} download className={classes.tableLink}>
            <PictureAsPdfOutlinedIcon></PictureAsPdfOutlinedIcon>
          </a>
        </TableCell>
    </TableRow>
  )
}
