import kegelsco from '../../img/sponsors/kegels-co.png'
import soubry from '../../img/sponsors/soubry.png'
import dreamland from '../../img/sponsors/dreamland.jpg'
import colruyt from '../../img/sponsors/colruyt.png'
import ecover from '../../img/sponsors/ecover.jpg'

const data = [
    {
        title: 'KEGELS & CO',
        name: 'Andre Kegels',
        website: 'https://www.kegels-co.com',
        media: kegelsco,
    },
    {
        title: 'SOUBRY',
        website: 'www.soubry.be',
        media: soubry,
    },
    {
        title: 'DREAMLAND',
        website: 'www.dreamland.be',
        media: dreamland,
    },
    {
        title: 'COLRUYT',
        website: 'www.colruyt.be/fr',
        media: colruyt,
    },
    {
        title: 'ECOVER',
        website: 'www.ecover.be',
        media: ecover,
    },
  ];

  export default data