import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {useLangValue }  from '../../Languages/languageContext'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import CustomTableRow from './CustomTableRow'
import OpenInBrowserOutlinedIcon from '@material-ui/icons/OpenInBrowserOutlined';

import loups from '../../data/loups/docsData'
import scouts from '../../data/scouts/docsData'

const useStyles = makeStyles(theme => ({
    grid: {
      marginLeft: 'auto'
    },
    card: {
        display: 'flex',
        backgroundColor: 'hsla(224, 30%, 5%, 0.72)',
        color: 'white',
        height: '100%',
        alignSelf: 'center'
      },
      cardDetails: {
        flex: 1,
        whiteSpace: 'pre-line',
      },
      cardMedia: {
          maxHeight: '200px',
          maxWidth: '200px',
          margin: '0 auto'
      },
      cardContent: {
        display: 'flex',
        flexDirection: 'column'
      },
      title: {
          marginBottom: '10px'
      },
      tableRow: {
        cursor: "pointer",
        '&:hover': {
          textDecoration: 'underline'
        }
      },
      tableLink: {
        textDecoration: 'none',
        color: 'white'
      },
      tableHeader: {
        borderBottom: '2px white solid'
      },
      tableText: {
        color: 'white'
      },
      tableCell: {
        color: 'white',
        borderBottom: '1px #ffffff80 dotted',
      },
}));


export default function Documents({section}) {
  const classes = useStyles();
  const [{ languagePackage }] = useLangValue();
  const ref= React.useRef()

  let newData
    switch (section) {
        case 'loups':
            newData = loups
            break;
        case 'scouts':
            newData = scouts
            break;
        default:
          break
    }
    
    const data = newData(languagePackage);


  return (
    <main className={classes.main}>
      <Grid container spacing={2} className={classes.cardGrid}>
        <Grid item className={classes.grid} xs={12} md={10}>
          <Card className={classes.card}>
            <div className={classes.cardDetails}>
              <CardContent>
                <Typography variant="subtitle1" paragraph>
                  {data.infoText}
                </Typography>
                <Table className={classes.table}>
                  <TableHead className={classes.tableHeader}>
                    <TableRow>
                      <TableCell className={classes.tableText}>{languagePackage.default.docs.name}</TableCell>
                      <TableCell className={classes.tableText} align="right"><GetAppOutlinedIcon></GetAppOutlinedIcon>{languagePackage.default.docs.download}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className={classes.tableBody}>
                  <TableRow className={classes.tableRow} onClick={() => ref.current.click()}>
                    <TableCell className={classes.tableCell} component='th'>
                      <div className={classes.flexTableCell}>
                        {languagePackage.default.docs.spotify}
                      </div>
                      
                    </TableCell>
                    <TableCell className={classes.tableCell} align='right'>
                      <a ref={ref} rel="noopener noreferrer" target="blank" href='https://open.spotify.com/playlist/1RRMTJZZleaxNp7Xi7qqOl?si=02grWYBlRw6yZCof16DOsw' className={classes.tableLink}>
                        <OpenInBrowserOutlinedIcon></OpenInBrowserOutlinedIcon>
                      </a>
                    </TableCell>
                </TableRow>
                    {data.docs.map(doc => <CustomTableRow doc={doc} key={doc.title}/>)}
                  </TableBody>
                </Table>

              </CardContent>
            </div>
          </Card>
        </Grid>
      </Grid>
    </main>
  );
}
