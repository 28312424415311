import NewPage from '../pages/newPage/newPage'
import contact from '../pages/contact/contact'
import Photo from '../pages/photos/photos'
import chefs from '../pages/chefs/chefs'
import home from '../pages/home/home'
import history from '../pages/history/history'
import newMember from '../pages/newMember/newMember'
import sponsors from '../pages/sponsors/sponsors'
import cafe from '../pages/cafe/cafe'
import program from '../pages/program/program'
import docs from '../pages/documents/documents'

//if adding new subsections, update also smallMenuItems.js state opbject!!!!

export const subsectionsunite = [
    {link: '/leaders', name: 'leaders', page: chefs},
    {link: '/local', name: 'local', page: NewPage},
    {link: '/cafe', name: 'cafe', page: cafe},
    {link: '/sponsor', name: 'sponsor', page: sponsors},
    {link: '/photo', name: 'photo', page: Photo},
    {link: '/history', name: 'history', page: history}
]
export const subsectionsloups = [
    {link: '/camp', name: 'camp', page: NewPage},
    {link: '/leaders', name: 'leaders', page: chefs},
    {link: '/program', name: 'program', page: program},
    {link: '/newMember', name: 'newMember', page: newMember},
    {link: '/photo', name: 'photo', page: Photo},
    {link: '/docs', name: 'docs', page: docs}
]
export const subsectionsscouts = [
    {link: '/camp', name: 'camp', page: NewPage},
    {link: '/leaders', name: 'leaders', page: chefs},
    {link: '/program', name: 'program', page: program},
    {link: '/newMember', name: 'newMember', page: newMember},
    {link: '/photo', name: 'photo', page: Photo},
    {link: '/docs', name: 'docs', page: docs}
]
export const menusections = [
    {link: '/home', name: 'home', page: home},
    {link: '/loups', name: 'loups', page: NewPage, subsection: subsectionsloups},
    {link: '/scouts', name: 'scouts', page: NewPage, subsection: subsectionsscouts},
    {link: '/unite', name: 'unite', page: NewPage, subsection: subsectionsunite},
    {link: '/activity', name: 'activity', page: NewPage},
    {link: '/contact', name: 'contact', page: contact},
]
