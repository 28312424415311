import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import scoutsimage from '../img/color.png';

import Hidden from '@material-ui/core/Hidden';
import { FaFacebookSquare, FaInstagram } from 'react-icons/fa'

const useStyles = makeStyles(theme => ({
  
  footer: {
    color: theme.palette.common.white,
    backgroundImage: `url(${scoutsimage})`,
    flexShrink: 0,
  },
  contactDetails: {
    height: '68px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    backgroundColor: 'rgba(0,0,0,0.45)'
  },
  contactText: {
    borderRight: '1px solid',
    borderColor: theme.palette.common.white,
    paddingRight: '10px',
    marginRight: '10px',
    color: theme.palette.common.white
  },
  contactColor: {
    color: theme.palette.common.white
  },
  paragraph: {
    margin: '0 auto'
  }
}));

function Footer() {
  const classes = useStyles();
  return (

    <footer className={classes.footer}>
        <div className={classes.contactDetails}>
            <Typography variant="subtitle1" align="center" className={classes.paragraph} color="textSecondary" component="p">
              <Hidden xsDown>
                <span className={classes.contactText}>49 Corbisier</span>
              </Hidden>
              <a className={classes.contactText} href="http://maps.google.com/?q=49%20corbisier" rel="noopener noreferrer" target="blank">Varenlaan 9 - 2610 Antwerpen</a>
              <a className={classes.contactText} href="https://www.facebook.com/49Corbisier" rel="noopener noreferrer" target="_blank"><FaFacebookSquare/></a>
              <a className={classes.contactColor} href="https://www.instagram.com/49corbisier/" rel="noopener noreferrer" target="_blank"><FaInstagram/></a>
            </Typography>
        </div>
    </footer>
  );
}

export default Footer;
