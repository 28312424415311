import media from '../../img/albums/2019/reunionparents.png'
import firstReunion from '../../img/reunions/loups/firstreunion.png'
import secondReunion from '../../img/reunions/loups/secondreunion.png'
import thirdReunion from '../../img/reunions/loups/thirdreunion.png'
import fourthReunion from '../../img/reunions/loups/fourthreunion.png'
import fifthReunion from '../../img/reunions/loups/fifthreunion.png'
import sixthReunion from '../../img/reunions/loups/sixthreunion.png'
import seventhReunion from '../../img/reunions/loups/seventhreunion.png'
import eighthReunion from '../../img/reunions/loups/eighthreunion.png'
import ninethReunion from '../../img/reunions/loups/ninethreunion.png'

import programLoups from '../../documents/programLoups.pdf'


//new Date(year : Number, month : Number, [date = 1 : Number, [hours = 0 : Number, [minutes = 0 : Number]])
// month starts at 0
export default function data(languagePackage) {
  const local = 'Varenlaan 9 - 2020 Antwerpen'
    return {
      program: programLoups,
      fileName: languagePackage.default.reunionsLoup.programLoups,
      reunions: [
      {
        reunion: languagePackage.default.reunionsLoup.firstReunion,
        media: firstReunion,
        date: new Date(2019,9,6,9,45),
        //new Date(year : Number, month : Number, [date = 1 : Number, [hours = 0 : Number, [minutes = 0 : Number]])
        // month starts at 0
        location: local,
      },
      {
        reunion: languagePackage.default.reunionsLoup.secondReunion,
        media: secondReunion,
        date: new Date(2019,9,13,9,45),
        location: 'Station Antwerpen-Berchem',
      },
      {
        reunion: languagePackage.default.reunionsLoup.thirdReunion,
        media: thirdReunion,
        date: new Date(2019,9,20,9,45),
        location: 'Info volgt nog',
      },
      {
        reunion: languagePackage.default.reunionsLoup.fourthReunion,
        media: fourthReunion,
        date: new Date(2019,10,10,9,45),
        location: local,
      },
      {
        reunion: languagePackage.default.reunionsLoup.fifthReunion,
        media: fifthReunion,
        date: new Date(2019,10,16,18,30),
        location: local,
      },
      {
        reunion: languagePackage.default.reunionsLoup.sixthReunion,
        media: sixthReunion,
        date: new Date(2019,10,24,9,45),
        location: 'IJsstadion Antarctica Moerelei 119-121 - Wilrijk',
      },
      {
        reunion: languagePackage.default.reunionsLoup.seventhReunion,
        media: seventhReunion,
        date: new Date(2019,11,1,9,45),
        location: local,
      },
      {
        reunion: languagePackage.default.reunionsLoup.eighthReunion,
        media: eighthReunion,
        date: new Date(2019,11,8,9,45),
        location: local,
      },
      {
        reunion: languagePackage.default.reunionsLoup.ninethReunion,
        media: ninethReunion,
        date: new Date(2019,11,15,9,45),
        location: local,
      }
    ]
  }
}