import React, {useState} from 'react';
import Collapse from '@material-ui/core/Collapse'
import { HashRouter as router, NavLink, withRouter } from "react-router-dom";
import useStyles from './styling'
import {useLangValue }  from '../Languages/languageContext'

import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import * as routes from '../routing/routing'
import { FaArrowUp } from 'react-icons/fa'


export default function SmallMenuItems(props) {
  const [{ languagePackage }] = useLangValue();

  const [subsectionShow, setSubsectionShow] = useState({
    loups: false,
    scouts: false,
    unite: false,
  });



  const toggleSubsection = (side) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    Object.keys(subsectionShow)
      .filter(Key => subsectionShow[Key] === true && Key !== side)
      .forEach(subKey => {
            setSubsectionShow(prev => ({...prev, [subKey]: false}))
           
      })
    setSubsectionShow(prev => ({...prev, [side]: !prev[side]}))
  };
  const classes = useStyles();


  return (
    <div
      className={classes.fullList}
      role="presentation"
      onKeyDown={props.toggleDrawer(false)}
    >

      <List>
        {routes.menusections.map((menusection) => (
            <ListItem key={menusection.link}>
                {
                    menusection.subsection ? (
                      <div
                          to={menusection.link}
                          className={classes.toolbarLink}
                          onClick={toggleSubsection(menusection.name)}
                          >
                          {languagePackage.default.nav[menusection.name]}
                          {
                              menusection.subsection && (
                                  <Collapse in={subsectionShow[menusection.name]} timeout={500} className={classes.subSmallMenu}>
                                      <ul className={classes.ul}>
                                      {menusection.subsection.map(section => (
                                      <li className={classes.li} key={section.name}>
                                          <NavLink
                                          to={menusection.link + section.link}
                                          key={section.name}
                                          className={classes.toolbarSubLink}
                                          onClick={props.toggleDrawer(false)}
                                          >
                                          {languagePackage.default.nav[section.name]}
                                          </NavLink>
                                      </li>
                                      ))}
                                      </ul>
                                  </Collapse>
                              )
                          }
                      </div>
                      ) : (
                      <NavLink
                          to={menusection.link}
                          className={classes.toolbarLink}
                          activeClassName={classes.activeToolbarLink}
                          onClick={props.toggleDrawer(false)}
                          >
                          {languagePackage.default.nav[menusection.name]}
                      </NavLink>
                      )
                }
              
                  <Divider />
              </ListItem>
        ))}
      </List>
      <div className={classes.iconDiv} onClick={props.toggleDrawer(false)}>
        <FaArrowUp className={classes.icon}/>
      </div>
    </div>


  );
}