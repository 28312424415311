import grandcamp2019scouts from '../../img/albums/2019/grandcamp2019scouts.jpg'
import grandcamp2019louveteauxGif from '../../img/albums/2019/grandcamp2019scouts.gif'
import reunionpompier2019 from '../../img/albums/2019/reunionpompier.jpg'
import reunionpompier2019Gif from '../../img/albums/2019/reunionpompier.gif'
import reunionjeudeville2018 from '../../img/albums/2018/reunionjeudeville2018.jpg'
import reunionjeudeville2018Gif from '../../img/albums/2018/reunionjeudeville2018.gif'
import reunionsurprise2018 from '../../img/albums/2018/reunionsurprise.jpg'
import reunionsurprise2018Gif from '../../img/albums/2018/reunionsurprise.gif'
import grandcampaouste2018 from '../../img/albums/2018/grandcamp2018scouts.jpg'
import grandcampaouste2018Gif from '../../img/albums/2018/grandcamp2018scouts.gif'
import reunionwallyball from '../../img/albums/2017/reunionwallyball.jpg'
import reunionjeudeville2017 from '../../img/albums/2017/reunionjeudeville.jpg'
import reunionjeudeville2017Gif from '../../img/albums/2017/reunionjeudeville.gif'
import reunionhighlandgames2017 from '../../img/albums/2017/reunionhighlandgames2017.jpg'
import reunionhighlandgames2017Gif from '../../img/albums/2017/reunionhighlandgames2017.gif'
import passage2017 from '../../img/albums/2017/passage2017.jpg'
import passage2017Gif from '../../img/albums/2017/passage2017.gif'
import grandcamp2016scouts from '../../img/albums/2016/grandcamp2016scouts.jpg'
import grandcamp2016scoutsGif from '../../img/albums/2016/grandcamp2016scouts.gif'
import grandcamp2017 from '../../img/albums/2017/grandcamp2017.jpg'
import grandcamp2017Gif from '../../img/albums/2017/grandcamp2017.gif'
import reunionhighlandgames2016 from '../../img/albums/2016/reunionhighlandgames.jpg'
import reunionhighlandgames2016Gif from '../../img/albums/2016/reunionhighlandgames.gif'
import grandcamp2015 from '../../img/albums/2015/grandcamp2015scouts.jpg'
import grandcamp2015Gif from '../../img/albums/2015/grandcamp2015scouts.gif'
import grandcamp2014 from '../../img/albums/2014/grandcamp2014.jpg'
import grandcamp2014Gif from '../../img/albums/2014/grandcamp2014.gif'
import grandcamp2013 from '../../img/albums/2013/grandcamp2013scouts.jpg'
import grandcamp2013Gif from '../../img/albums/2013/grandcamp2013scouts.gif'
import grandcamp2012 from '../../img/albums/2012/grandcamp2012scouts.jpg'
import grandcamp2012Gif from '../../img/albums/2012/grandcamp2012scouts.gif'




export default function data(languagePackage) {
    return [
      {
        year: '2019',
        photos:
          [{
            title: languagePackage.default.photos.clairfayts,
            link: 'https://photos.app.goo.gl/PMimtchd37sWpDKE7',
            media: grandcamp2019scouts,
            gif: grandcamp2019louveteauxGif,
          },
          {
            title: languagePackage.default.photos.reunionpompier2019,
            link: 'https://photos.app.goo.gl/8oFTx5arbfqyBoEv9',
            media: reunionpompier2019,
            gif: reunionpompier2019Gif,
          },
        ]
      },
      {
        year: '2018',
        photos:
        [ {
          title: languagePackage.default.photos.reunionjeudeville2018,
          link: 'https://photos.app.goo.gl/si3Fh7nKQUedxVSd9',
          media: reunionjeudeville2018,
          gif: reunionjeudeville2018Gif,
        },
        {
          title: languagePackage.default.photos.reunionsurprise2018,
          link: 'https://photos.app.goo.gl/A7uaKrvLW9TGWQn46',
          media: reunionsurprise2018,
          gif: reunionsurprise2018Gif,
        },
        {
          title: languagePackage.default.photos.grandcamp2018,
          link: 'https://photos.app.goo.gl/rrCt3Ffwssy4Bkg49',
          media: grandcampaouste2018,
          gif: grandcampaouste2018Gif,
        },
        
        ]
      },
      {
        year: '2017',
        photos:
        [ 
        {
          title: languagePackage.default.photos.reunionwallyball,
          link: 'https://photos.app.goo.gl/CZuwHS7NYxUzMdBe2',
          media: reunionwallyball,
        },
        {
          title: languagePackage.default.photos.reunionjeudeville2017,
          link: 'https://photos.app.goo.gl/yW8HHAQKu6kj7leD2',
          media: reunionjeudeville2017,
          gif: reunionjeudeville2017Gif,
        },
        {
          title: languagePackage.default.photos.reunionhighlandgames2017,
          link: 'https://photos.app.goo.gl/TgfNAtAAb3dXw3qJ2',
          media: reunionhighlandgames2017,
          gif: reunionhighlandgames2017Gif,
        },
        {
          title: languagePackage.default.photos.passage2017,
          link: 'https://photos.app.goo.gl/mLXnOxzzTOpBDd443',
          media: passage2017,
          gif: passage2017Gif,
        },
        {
          title: languagePackage.default.photos.grandcamp2017,
          link: 'https://goo.gl/photos/wztXC5PR59XB8yzQ9',
          media: grandcamp2017,
          gif: grandcamp2017Gif,
        },
        ]
      },
      {
        year: '2016',
        photos:
        [ 
        {
          title: languagePackage.default.photos.highlandgames2016,
          link: 'https://goo.gl/photos/RxvgZ2xNYVQxWaFR7',
          media: reunionhighlandgames2016,
          gif: reunionhighlandgames2016Gif,
        },  
        {
          title: languagePackage.default.photos.grandcamp2016scouts,
          link: 'https://photos.app.goo.gl/pScfK523CJAucYu7A',
          media: grandcamp2016scouts,
          gif: grandcamp2016scoutsGif,
        },
        
        ]
      },
      {
        year: '2015',
        photos:
        [ 
        {
          title: languagePackage.default.photos.grandcamp2015scouts,
          link: 'https://photos.app.goo.gl/EKREUSxwy8WcWFU69',
          media: grandcamp2015,
          gif: grandcamp2015Gif,
        },  
        
        ]
      },
      {
        year: '2014',
        photos:
        [ 
        {
          title: languagePackage.default.photos.grandcamp2014scouts,
          link: 'https://goo.gl/photos/9VTC7PTCRujLCc486',
          media: grandcamp2014,
          gif: grandcamp2014Gif,
        },  
        
        ]
      },
      {
        year: '2013',
        photos:
        [ 
        {
          title: languagePackage.default.photos.grandcamp2013scouts,
          link: 'https://photos.app.goo.gl/dvieKznCe5BHdKhh7',
          media: grandcamp2013,
          gif: grandcamp2013Gif,
        },  
        
        ]
      },
      {
        year: '2012',
        photos:
        [ 
        {
          title: languagePackage.default.photos.grandcamp2012scouts,
          link: 'https://photos.app.goo.gl/CMEgu11jVjXNnsNm7',
          media: grandcamp2012,
          gif: grandcamp2012Gif,
        },  
        
        ]
      },

  ];
}