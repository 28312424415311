import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Reunion from '../../modules/reunion'
import { useLangValue } from '../../Languages/languageContext';
import { useQuery, gql } from "@apollo/client";

const useStyles = makeStyles(theme => ({
  downloadDiv: {
    backgroundColor: 'hsla(224, 30%, 5%, 0.72)',
    textAlign: 'center',
    height: '40px',
    marginBottom: '20px',
    display: 'flex'
  },
  link: {
    color: 'white',
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    margin: 'auto',
    cursor: 'pointer',
    "&:hover" : {
      textDecoration: 'underline'
    }
  },
  linkIcon: {
    marginRight: '5px'
  }
}))

const reunionQuery = (section, locale) => gql`
  {
    reunions(where: {sectionType: ${section}}, locales: ${locale}, first: 100)  {
      name
      date
      adress
      addition
      id
      picture {
        url
        fileName
        id
      }
    }
  }
`;

export default function Program({section}) {
  const [{ value }] = useLangValue();
  const classes = useStyles({});
  const { data, loading, error } = useQuery(reunionQuery(section, value));

  if (loading) return "Loading...";
  if (error) return <pre>{error.message}</pre>
  return (
        <main>
          <div className={classes.downloadDiv}>
            {/* <a className={classes.link} download={reunionData.fileName} href={reunionData.program}><GetAppOutlinedIcon className={classes.linkIcon}/><span>{languagePackage.default.documents.downloadProgram}</span></a> */}
          </div>
        <Grid container spacing={4}>
            {data.reunions.map((reunion, index) => (
              <Grid item key={reunion.name} xs={12}>
                <Reunion reunion={reunion} even={index % 2 === 0}/>
              </Grid>
            ))}
          </Grid>
        </main>
  );
}
