import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {useLangValue }  from '../../Languages/languageContext'

import MobileStepper from '@material-ui/core/MobileStepper';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Person from '../../modules/person'
import { useQuery, gql } from "@apollo/client";


import loups from '../../data/loups/newmembersData'
import scouts from '../../data/scouts/newmembersData'

const useStyles = makeStyles(theme => ({
    card: {
        display: 'flex',
        backgroundColor: 'hsla(224, 30%, 5%, 0.72)',
        color: 'white',
        height: '100%',
      },
      carouselCard: {
        display: 'flex',
        backgroundColor: 'hsla(224, 30%, 5%, 0.72)',
        color: 'white',
        height: '100%',
        flexDirection: 'column',
      },
      carouselGrid: {
        margin: '0 auto'
      },
      cardDetails: {
        flex: 1,
        whiteSpace: 'pre-line',
      },

      cardMedia: {
          maxHeight: '200px',
          maxWidth: '200px',
          margin: '0 auto'
      },
      cardContent: {
        display: 'flex',
        flexDirection: 'column'
      },
      root: {
        maxWidth: 400,
        flexGrow: 1,
      },
      carouselHeader: {
        display: 'flex',
        alignItems: 'center',
        height: 50,
        backgroundColor: 'hsla(224, 30%, 5%, 0)',
        color: 'white'
      },
      carouselFooter: {
        backgroundColor: 'hsla(224, 30%, 5%, 0)',
        color: 'white'
      },
      carouselHeaderText: {
        margin: '0 auto'
      },
      carouselButtons: {
        color: 'white'
      },
      carouselContent: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
      },
      imageText: {
        padding: '15px',
        width: '50%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
      },
      img: {
        height: 255,
        maxWidth: 400,
        overflow: 'hidden',
        display: 'block',
        margin: '0 auto',
      },
      title: {
          marginBottom: '10px'
      }
}));

const chefsQuery = (section, locale) => gql`
  {
    chefs(where: {sectionType: ${section}, isContact: true})  {
      name
      email
      phoneNumber
      totem
      photo {
        url
        fileName
        id
      }
      id
    }
  }
`;


export default function NewMember({section}) {
  const classes = useStyles();
  const [{ languagePackage }] = useLangValue();
  const [activeStep, setActiveStep] = React.useState(0);

  const { data, loading, error } = useQuery(chefsQuery(section));
  
  if (loading) return "Loading...";
  if (error) return <pre>{error.message}</pre>

  function handleNext() {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  }

  function handleBack() {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  }
  let newData
    switch (section) {
        case 'loups':
            newData = loups
            break;
        case 'scouts':
            newData = scouts
            break;
        default:
          break
    }
    
    const newMemberData = newData(languagePackage);
    const maxSteps = newMemberData.uniform.length;


  return (
        <main className={classes.main}>
          <Grid container spacing={4} className={classes.cardGrid}>
            <Grid item xs={12}>
                  <Card className={classes.card}>
                    <div className={classes.cardDetails}>
                      <CardContent>
                        <Typography component="h2" variant="h5" className={classes.title}>
                          {languagePackage.default.newMember.info}
                        </Typography>
                        <Typography variant="subtitle1" paragraph>
                          {newMemberData.infoText}
                        </Typography>
                      </CardContent>
                    </div>
                  </Card>
              </Grid>
            <Grid item xs={12} md={4}>
                <Card className={classes.card}>
                <div className={classes.cardDetails}>
                    <CardContent className={classes.cardContent}>
                    <Typography component="h2" variant="h5" className={classes.title}>
                        {languagePackage.default.newMember.tryout}
                    </Typography>
                    <Typography variant="subtitle1" paragraph>
                        {newMemberData.tryoutText}
                    </Typography>
                    </CardContent>
                </div>
                </Card>
            </Grid>
            <Grid item xs={12} md={4}>
                <Card className={classes.card}>
                <div className={classes.cardDetails}>
                    <CardContent className={classes.cardContent}>
                    <Typography component="h2" variant="h5" className={classes.title}>
                        {newMemberData.stay}
                    </Typography>
                    <Typography variant="subtitle1" paragraph>
                        {newMemberData.stayText}
                    </Typography>
                    </CardContent>
                </div>
                </Card>
            </Grid>
            <Grid item xs={12} md={4}>
                <Person person={data.chefs[0]}/>
            </Grid>
            <Grid item xs={12} md={8} lg={6} className={classes.carouselGrid}>
                <Card className={classes.carouselCard}>
                    <Paper elevation={0} className={classes.carouselHeader}>
                        <Typography className={classes.carouselHeaderText}>{newMemberData.uniform[activeStep].label}</Typography>
                    </Paper>
                    <div className={classes.carouselContent}>
                        <img
                            className={classes.img}
                            src={newMemberData.uniform[activeStep].imgPath}
                            alt={newMemberData.uniform[activeStep].label}
                        />
                        <p className={classes.imageText}>{newMemberData.uniform[activeStep].extraInfo}</p>
                    </div>
                    <MobileStepper
                        steps={maxSteps}
                        position="static"
                        variant="text"
                        activeStep={activeStep}
                        nextButton={
                        <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1} className={classes.carouselButtons}>
                            Next
                            <KeyboardArrowRight />
                        </Button>
                        }
                        backButton={
                        <Button size="small" onClick={handleBack} disabled={activeStep === 0} className={classes.carouselButtons}>
                            <KeyboardArrowLeft />
                            Back
                        </Button>
                        }
                        className={classes.carouselFooter}
                    />
                  </Card>
              </Grid>
          </Grid>
        </main>
  );
}
