import contactData from '../contactData'
import pull from '../../img/uniform/pull.png'
import foulard from '../../img/uniform/foulard.png'
import cap from '../../img/uniform/casquette-verte.png'
import shorts from '../../img/uniform/shorts.png'
import socks from '../../img/uniform/kousen.png'

export default function data(languagePackage) {
    return {
            infoText: languagePackage.default.newMember.infoTextLoups,
            tryoutText: languagePackage.default.newMember.tryoutTextLoups,
            stayText: languagePackage.default.newMember.stayTextLoups,
            stay: languagePackage.default.newMember.stayLoups,
            contactPerson: contactData.filter((person) => {
                return person.title === 'Akéla'
            })[0],
            uniform: [
                {
                label: languagePackage.default.uniform.cap,
                extraInfo: languagePackage.default.uniform.capInfo,
                imgPath: cap,
                },
                {
                label: languagePackage.default.uniform.foulard,
                extraInfo: languagePackage.default.uniform.foulardLoupsInfo,
                imgPath: foulard,
                },
                {
                label: languagePackage.default.uniform.pull,
                extraInfo: languagePackage.default.uniform.pullInfo,
                imgPath: pull,
                },
                {
                label: languagePackage.default.uniform.shorts,
                extraInfo: languagePackage.default.uniform.shortsInfo,
                imgPath: shorts,
                },
                {
                label: languagePackage.default.uniform.socks,
                extraInfo: languagePackage.default.uniform.socksInfo,
                imgPath: socks,
                },
            ]
        }
  }