import React from 'react';
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'



import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';

const useStyles = makeStyles(theme => ({
    scrollDiv: {
        overflowY: 'auto',
        maxHeight: '70vh',
        overflowX: 'hidden',
        height: '70vh',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            height: '25vh',
        },
      },
    card: {
        backgroundColor: 'hsla(159, 10%, 70%, 0.2)',
        border: 'solid 3px hsl(159, 40%, 30%)',
        textDecoration: 'none',
        color: 'white',
        '&:hover': {
            backgroundColor: 'hsla(159, 50%, 55%, 0.2)',
            border: 'solid 3px hsl(159, 40%, 30%)',
        }
    },
    actionArea: { 
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'none'
        }
    },
    secondaryText: { 
        color: 'hsla(159, 40%, 90%, 0.8)'
    }
  }));

export default function UpdateList({updates}) {
    const classes = useStyles();
   
    return (
        
        <div className={classes.scrollDiv}>
            <List>
            {updates.map((update) => (
                <ListItem key={update.title}>
                    {
                        <CardActionArea component={Link} to={update.link} className={classes.actionArea}>
                        <Card className={classes.card}>
                        <div className={classes.cardDetails}>
                            <CardContent>
                            <Typography component="h2" variant={'body1'}>
                                {update.title}
                            </Typography>
                            <Typography variant="subtitle2" className={classes.secondaryText}>
                                {update.extra}
                            </Typography>
                            </CardContent>
                        </div>
                        </Card>
                    </CardActionArea>
                    }
                </ListItem>
            ))}
        </List>
      </div>
    );
}
