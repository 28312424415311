import React from 'react';
import Grid from '@material-ui/core/Grid';
import data from '../../data/unite/sponsorData'
import Sponsor from '../../modules/sponsor'



export default function Contact() {

  return (
        <main>
          <Grid container spacing={4}>
            {data.map(person => (
              <Grid item key={person.Totem} xs={12} md={6} lg={4}>
                <Sponsor person={person}/>
              </Grid>
            ))}
          </Grid>
        </main>
  );
}
