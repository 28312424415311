import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import GridList from '@material-ui/core/GridList'
import CardContent from '@material-ui/core/CardContent';
import {useLangValue }  from '../../Languages/languageContext'
import GridListPhotoTile from '../../pages/photos/gridListPhotoTile'
import photos_cafe from '../photos/photos_cafe'



const useStyles = makeStyles(theme => ({
    
    card: {
        display: 'flex',
        backgroundColor: 'hsla(224, 30%, 5%, 0.72)',
        color: 'white'
      },
      cardDetails: {
        flex: 1,
        whiteSpace: 'pre-line',
      },
      cardMedia: {
        width: 160,
      },
      markdown: {
        ...theme.typography.body2,
        padding: theme.spacing(3, 0),
      },
      cardContent: {
        display: 'flex',
        flexDirection: 'column'
      },
      image: {
        width: '20vw',
        margin: '0 auto',
        [theme.breakpoints.down('md')]: {
            width: '30vw',
        },
        [theme.breakpoints.down('sm')]: {
            width: '50vw',
        },
        height: '100%',
        border: 'solid hsla(224, 15%, 28%, 1) 2px',
        '&:hover': {
            border: 'solid hsla(224, 20%, 70%, 1) 3px',
        }

    }
}));


export default function Contact() {
  const classes = useStyles();
  const [{ lang, languagePackage }] = useLangValue();
  const photos = photos_cafe(languagePackage)

  return (
        <main className={classes.main}>
          <Grid container spacing={4} className={classes.cardGrid}>
              <Grid item xs={12} md={8}>
                  <Card className={classes.card}>
                    <div className={classes.cardDetails}>
                      <CardContent className={classes.cardContent}>
                            {languagePackage.default.cafe.first}
                            <Typography component="h2" variant="h5">
                            {languagePackage.default.cafe.events}
                            </Typography>
                            <Typography component="h2" variant="h5">
                                {languagePackage.default.cafe.passed}
                            </Typography>
                            {languagePackage.default.cafe.passedevents}
                            <Typography component="h2" variant="h5">
                                {languagePackage.default.cafe.planned}
                            </Typography>

                      </CardContent>
                    </div>
                  </Card>
              </Grid>
              <Grid item xs={12} md={4}>
              <GridList cellHeight={'auto'} className={classes.gridList} style={{ margin: '0 auto'}} cols={1}>
                 {photos.map(photo => (
                    <GridListPhotoTile photo={photo} classes={{image: classes.image}}/>  
                 ))}   
                 </GridList>
              </Grid>
              
          </Grid>
        </main>
  );
}
