import React from 'react'

import { useLangValue } from './languageContext';
import LanguagePage from '../pages/languagePage'

export default function LanguageCheck({ children }){
  
  const [{ lang }, dispatch] = useLangValue();
  if(!lang) {
    const savedLanguage = localStorage.getItem('defaultLanguage')
    savedLanguage && dispatch({newLang:savedLanguage})
  }
  return (
      lang
        ? children
        : <LanguagePage/>
  )
}