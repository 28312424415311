import contactData from '../contactData'
import chemise from '../../img/uniform/hemd.png'
import foulard from '../../img/uniform/foulard.png'
import hood from '../../img/uniform/chapeau.png'
import shorts from '../../img/uniform/shorts.png'
import socks from '../../img/uniform/kousen.png'

export default function data(languagePackage) {
    return {
            infoText: languagePackage.default.newMember.infoTextScouts,
            tryoutText: languagePackage.default.newMember.tryoutTextScouts,
            stayText: languagePackage.default.newMember.stayTextScouts,
            stay: languagePackage.default.newMember.stayScouts,
            contactPerson: contactData.filter((person) => {
                return person.title === 'Master'
            })[0],
            uniform: [
                {
                label: languagePackage.default.uniform.hood,
                extraInfo: languagePackage.default.uniform.hoodInfo,
                imgPath: hood,
                },
                {
                label: languagePackage.default.uniform.foulard,
                extraInfo: languagePackage.default.uniform.foulardScoutsInfo,
                imgPath: foulard,
                },
                {
                label: languagePackage.default.uniform.chemise,
                extraInfo: languagePackage.default.uniform.chemiseInfo,
                imgPath: chemise,
                },
                {
                label: languagePackage.default.uniform.shorts,
                extraInfo: languagePackage.default.uniform.shortsInfo,
                imgPath: shorts,
                },
                {
                label: languagePackage.default.uniform.socks,
                extraInfo: languagePackage.default.uniform.socksInfo,
                imgPath: socks,
                },
            ]
        }
  }