import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem'
import {useLangValue }  from '../../Languages/languageContext'
import loupsDates from '../../data/loups/reunionsData'
import scoutsDates from '../../data/scouts/reunionsData'
import Reunion from '../../modules/reunion'
import Grid from '@material-ui/core/Grid';
import { Link } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";



const useStyles = makeStyles(theme => ({
    card: {
        width: '100%',
        marginRight: '0px',
        border: 'solid 1px hsl(159, 40%, 30%)',
        '&:hover': {
            backgroundColor: 'hsla(159, 50%, 55%, 0.2)',
        }
      },
      text: {
          color: 'white',
      },
      grid: {
          textAlign: 'center',
          margin: 'auto',
      }
  }));

  const getClosestReunion = (data) => {
    const today = new Date();
    return data.length > 0 ? data.reduce((a, b) => {
        const adiff = new Date(a.date) - today;
        const bdiff = new Date(b.date) - today
        return adiff > 0 && (adiff < Math.abs(bdiff) || bdiff < 0) ? a : b;
      }) : null;
  }

  const reunionQuery = (locale) => gql`
  {
    reunions(locales: ${locale}, first: 100)  {
      name
      date
      adress
      addition
      id
      sectionType
      picture {
        url
        fileName
        id
      }
    }
  }
`;

export default function FirstReunion() {
    const classes = useStyles();
    const [{ languagePackage, value }] = useLangValue();
    const { data, loading, error } = useQuery(reunionQuery(value));

    if (loading) return "Loading...";
    if (error) return <pre>{error.message}</pre>
    const closestLoups = getClosestReunion(data.reunions.filter(reunion => reunion.sectionType === 'loups'));
    const closestScouts = getClosestReunion(data.reunions.filter(reunion => reunion.sectionType === 'scouts'));
    return (
            <List>
                {
                    closestLoups && <ListItem component={Link} to="/loups/program">

                                        <Grid container>
                                            <Grid item xs={12} md={3} className={classes.grid}>
                                                <h1 className={classes.text}>{languagePackage.default.nav.loups}</h1>
                                            </Grid>
                                            <Grid item xs={12} md={9}>
                                                <Reunion reunion={closestLoups} even={true} classes={{card: classes.card}} noLink={true}/>
                                            </Grid>
                                        </Grid>
                                    </ListItem>
                }
                {
                    closestScouts && <ListItem component={Link} to="/scouts/program">
                        <Grid container>
                            <Grid item xs={12} md={3} className={classes.grid}>
                                <h1 className={classes.text}>{languagePackage.default.nav.scouts}</h1>
                            </Grid>
                            <Grid item xs={12} md={9}>
                                <Reunion reunion={closestScouts} even={true} classes={{card: classes.card}} noLink={true}/>
                            </Grid>
                        </Grid>
                    </ListItem>
                }
        </List>
    );
}
