import React from 'react';
import { FacebookProvider, MessageUs ,Like, Page,EmbeddedPost } from 'react-facebook';
import InstagramEmbed from 'react-instagram-embed';
  
function NewPage() {
    return(
      
      <h1 style={{color: 'white'}}>Under construction</h1>

        // <FacebookProvider appId="1417655268388629">
        
        // <Page href="https://www.facebook.com/49corbisier" tabs="timeline" height="100px" />
        
        // <InstagramEmbed
        //   url='https://instagr.am/p/B1gCHjqCQmZ/'
        //   maxWidth={undefined}
        //   hideCaption={false}
        //   containerTagName='div'
        //   protocol=''
        //   injectScript
        //   onLoading={() => {}}
        //   onSuccess={() => {}}
        //   onAfterRender={() => {}}
        //   onFailure={() => {}}
        //   />
        // </FacebookProvider>
        

    )
}


export default NewPage