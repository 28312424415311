import grandcamp2019louveteaux from '../../img/albums/2019/grandcamp2019louveteaux.jpg'
import grandcamp2019louveteauxGif from '../../img/albums/2019/grandcamp2019louveteaux.gif'
import reunionparents2019 from '../../img/albums/2019/reunionparents.png'
import reunionparents2019Gif from '../../img/albums/2019/reunionparents.gif'
import reunionpatinage2018 from '../../img/albums/2018/reunionpatinage.jpg'
import reunionpatinage2018Gif from '../../img/albums/2018/reunionpatinage.gif'
import reunionjeudeville2018 from '../../img/albums/2018/reunionjeudeville2018.jpg'
import reunionjeudeville2018Gif from '../../img/albums/2018/reunionjeudeville2018.gif'
import grandcamp2018louveteaux from '../../img/albums/2018/grandcamp2018louveteaux.jpg'
import grandcamp2018louveteauxGif from '../../img/albums/2018/grandcamp2018louveteaux.gif'
import grandcamp2017 from '../../img/albums/2017/grandcamp2017.jpg'
import grandcamp2017Gif from '../../img/albums/2017/grandcamp2017.gif'
import highlandgames2016 from '../../img/albums/2016/reunionhighlandgames.jpg'
import highlandgames2016Gif from '../../img/albums/2016/reunionhighlandgames.gif'
import grandcamp2016 from '../../img/albums/2016/grandcamp2016.jpg'
import grandcamp2016Gif from '../../img/albums/2016/grandcamp2016.gif'
import grandcamp2015 from '../../img/albums/2015/grandcamp2015.jpg'
import grandcamp2015Gif from '../../img/albums/2015/grandcamp2015.gif'
import grandcamp2014 from '../../img/albums/2014/grandcamp2014louveteaux.jpg'
import grandcamp2014Gif from '../../img/albums/2014/grandcamp2014louveteaux.gif'
import reunioncasino2017 from '../../img/albums/2017/reunioncasino2017.jpg'
import reunioncasino2017Gif from '../../img/albums/2017/reunioncasino2017.gif'
import reunion17092017 from '../../img/albums/2017/reunion17092017.jpg'
import reunion17092017Gif from '../../img/albums/2017/reunion17092017.gif'
import reunionjeuxolympiques2017 from '../../img/albums/2017/reunionjeuxolympiques2017.jpg'
import reunionjeuxolympiques2017Gif from '../../img/albums/2017/reunionjeuxolympiques2017.gif'
import reunioncombat from '../../img/albums/2017/reunioncombat2017.jpg'
import reunioncombatGif from '../../img/albums/2017/reunioncombat2017.gif'
import reuniondeville2017 from '../../img/albums/2017/reunionjeudeville.jpg'
import reuniondeville2017Gif from '../../img/albums/2017/reunionjeudeville.gif'
import reunionhighlandgames2017 from '../../img/albums/2017/reunionhighlandgames2017.jpg'
import reunionhighlandgames2017Gif from '../../img/albums/2017/reunionhighlandgames2017.gif'
import passage2017 from '../../img/albums/2017/passage2017.jpg'
import passage2017Gif from '../../img/albums/2017/passage2017.gif'
import reunionmountainbike2018 from '../../img/albums/2018/reunionmountainbike.jpg'
import reunionmountainbike2018Gif from '../../img/albums/2018/reunionmountainbike.gif'
import reunionsport2018 from '../../img/albums/2018/reunionsport.jpg'
import reunionsport2018Gif from '../../img/albums/2018/reunionsport.gif'
import reunionbg2018 from '../../img/albums/2018/reunionbg.jpg'
import reunionbg2018Gif from '../../img/albums/2018/reunionbg.gif'
import reunionpapa2018 from '../../img/albums/2018/reunionpapa.jpg'
import reunionpapa2018Gif from '../../img/albums/2018/reunionpapa.gif'
import reunioner2018 from '../../img/albums/2018/reunioner.jpg'
import reunioner2018Gif from '../../img/albums/2018/reunioner.gif'
import reunionzoo2018 from '../../img/albums/2018/reunionzoo.jpg'
import reunionzoo2018Gif from '../../img/albums/2018/reunionzoo.gif'
import intermeute2018 from '../../img/albums/2018/intermeute2018.jpg'
import intermeute2018Gif from '../../img/albums/2018/intermeute2018.gif'
import reunionhighlandgames2018 from '../../img/albums/2018/highlandgames2018.jpg'
import reunionhighlandgames2018Gif from '../../img/albums/2018/highlandgames2018.gif'
import jeudeville2019 from '../../img/albums/2019/jeudeville2019.jpg'





export default function data(languagePackage) {
  return [
    {
      year: '2019',
      photos: [
        {
          title:languagePackage.default.photos.jeudeville2019,
          link: 'https://photos.app.goo.gl/HE9LDpgbvZy7XPoE7',
          media: jeudeville2019,

        },
        {
          title: languagePackage.default.photos.clairfayts,
          link: 'https://photos.app.goo.gl/4DZVuNgSadkAw92c8',
          media: grandcamp2019louveteaux,
          gif: grandcamp2019louveteauxGif,
        },      {
          title: languagePackage.default.photos.reunionparents2019,
          link: 'https://photos.app.goo.gl/kfYmkPNe3bqWMXXp6',
          media: reunionparents2019,
          gif: reunionparents2019Gif,
        }
      ]
    },
    {
      year: '2018',
      photos: [{
          title: languagePackage.default.photos.reunionpatinage2018,
          link: 'https://photos.app.goo.gl/ce9pCrzLUKAPxkfaA',
          media: reunionpatinage2018,
          gif: reunionpatinage2018Gif,
        },
        {
          title: languagePackage.default.photos.reunionjeudeville2018,
          link: 'https://photos.app.goo.gl/si3Fh7nKQUedxVSd9',
          media: reunionjeudeville2018,
          gif: reunionjeudeville2018Gif,
        },
        {
          title: languagePackage.default.photos.reunionhighlandgames2018,
          link: 'https://photos.app.goo.gl/hSFaFsGEzznps4eE8',
          media: reunionhighlandgames2018,
          gif: reunionhighlandgames2018Gif,
        },
        {
          title: languagePackage.default.photos.grandcamp2018,
          link: 'https://photos.app.goo.gl/rrCt3Ffwssy4Bkg49',
          media: grandcamp2018louveteaux,
          gif: grandcamp2018louveteauxGif,
        },
        {
          title: languagePackage.default.photos.intermeute2018,
          link: 'https://photos.app.goo.gl/1rGdX1ifrNF8ISOn1',
          media: intermeute2018,
          gif: intermeute2018Gif,
        },
        {
          title: languagePackage.default.photos.reunionzoo2018,
          link: 'https://photos.app.goo.gl/PSpuPFryDYSLU1Vz1',
          media: reunionzoo2018,
          gif: reunionzoo2018Gif,
        },
        {
          title: languagePackage.default.photos.reunioner2018,
          link: 'https://photos.app.goo.gl/uDvGN0K7jM5YMqzW2',
          media: reunioner2018,
          gif: reunioner2018Gif,
        },
        {
          title: languagePackage.default.photos.reunionpapa2018,
          link: 'https://photos.app.goo.gl/KgpoyiUKEikmjocq1',
          media: reunionpapa2018,
          gif: reunionpapa2018Gif,
        },
        {
          title: languagePackage.default.photos.reunionbg2018,
          link: 'https://photos.app.goo.gl/XbYpq0RkvzBIXOjG2',
          media: reunionbg2018,
          gif: reunionbg2018Gif,
        },
        {
          title: languagePackage.default.photos.reunionsport2018,
          link: 'https://photos.app.goo.gl/dRLjIfxc3SjWKXCA3',
          media: reunionsport2018,
          gif: reunionsport2018Gif,
        },
        {
          title: languagePackage.default.photos.reunionmountainbike2018,
          link: 'https://photos.app.goo.gl/Kx0u3OpsBInnaztn1',
          media: reunionmountainbike2018,
          gif: reunionmountainbike2018Gif,
        }
      ]
    },
    {
      year: '2017',
      photos: [
        {
          title: languagePackage.default.photos.reunioncasino2017,
          link: 'https://photos.app.goo.gl/h3B52RWHsjXARO9N2',
          media: reunioncasino2017,
          gif: reunioncasino2017Gif,
        },
        {
          title: languagePackage.default.photos.reunionjeudeville2017,
          link: 'https://photos.app.goo.gl/yW8HHAQKu6kj7leD2',
          media: reuniondeville2017,
          gif: reuniondeville2017Gif,
        },
        {
          title: languagePackage.default.photos.reunionhighlandgames2017,
          link: 'https://photos.app.goo.gl/TgfNAtAAb3dXw3qJ2',
          media: reunionhighlandgames2017,
          gif: reunionhighlandgames2017Gif,
        },
        {
          title: languagePackage.default.photos.reunionjeuxolympiques2017,
          link: 'https://photos.app.goo.gl/giKPgDh4r1URvEby2',
          media: reunionjeuxolympiques2017,
          gif: reunionjeuxolympiques2017Gif,
        },
        {
          title: languagePackage.default.photos.reunioncombat,
          link: 'https://photos.app.goo.gl/UUIano3I1GiuRimM2',
          media: reunioncombat,
          gif: reunioncombatGif,
        },
        {
          title: languagePackage.default.photos.reunion17092017,
          link: 'https://photos.app.goo.gl/IEnKvzbPakp7ztoo2',
          media: reunion17092017,
          gif: reunion17092017Gif,
        },
        {
          title: languagePackage.default.photos.passage2017,
          link: 'https://photos.app.goo.gl/mLXnOxzzTOpBDd443',
          media: passage2017,
          gif: passage2017Gif,
        },
        {
          title: languagePackage.default.photos.grandcamp2017,
          link: 'https://goo.gl/photos/wztXC5PR59XB8yzQ9',
          media: grandcamp2017,
          gif: grandcamp2017Gif,
        }
        
      ]
    },
    {
      year: '2016',
      photos: [{
          title: languagePackage.default.photos.highlandgames2016,
          link: 'https://goo.gl/photos/RxvgZ2xNYVQxWaFR7',
          media: highlandgames2016,
          gif: highlandgames2016Gif,
        },
        {
          title: languagePackage.default.photos.grandcamp2016,
          link: 'https://goo.gl/photos/ULiNgcTaFVsBmWuj9',
          media: grandcamp2016,
          gif: grandcamp2016Gif,
        }
      ]
    },
    {
      year: '2015',
      photos: [{
          title: languagePackage.default.photos.grandcamp2015,
          link: 'https://goo.gl/photos/GhkHSWuDnAYAznmL7',
          media: grandcamp2015,
          gif: grandcamp2015Gif,
        }
      ]
    },
    {
      year: '2014',
      photos: [
        {
          title: languagePackage.default.photos.grandcamp2014,
          link: 'https://goo.gl/photos/9VTC7PTCRujLCc486',
          media: grandcamp2014,
          gif: grandcamp2014Gif,
        }
      ]
    }
  ];
}