import local from '../img/people/DidierJDV.png'
import master from '../img/people/arnaudjanssensdevarebeke.jpg'
import akela from '../img/people/pierrevermeire.jpg'
import cu from '../img/people/ivanjdv.jpg'

const data = [
    {
      title: 'CU',
      name: 'Ivan Janssens de Varebeke',
      email:'49corbisier.unite@gmail.com',
      phone: '+32 493 78 51 91',
      media: cu,
    },
    {
      title: 'Master',
      name: 'Arnaud Janssens de Varebeke',
      email: '49corbisier.scouts@gmail.com',
      phone: '+32 497 94 64 17',
      media: master,
    },
    {
      title: 'Akéla',
      name: 'Pierre Vermeire',
      email: '49corbisier.loups@gmail.com',
      phone: '+32 470 01 17 26',
      media: akela,
    },
    {
      title: 'Local',
      name: 'Didier Janssens de Varebeke',
      email:'49corbisier.local@gmail.com',
      phone: '+32 495 53 62 00',
      media: local,
    },
  ];

  export default data