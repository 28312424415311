import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Person from '../../modules/person'
import { useQuery, gql } from "@apollo/client";


const chefsQuery = (section) => gql`
  {
    chefs(where: {sectionType: ${section}}, orderBy: orderContact_ASC)  {
      name
      email
      phoneNumber
      totem
      photo {
        url
        fileName
        id
      }
      id
    }
  }
`;

export default function Contact({section}) {
  const { data, loading, error } = useQuery(chefsQuery(section));

  if (loading) return "Loading...";
  if (error) return <pre>{error.message}</pre>
  return (
        <main>
          <Grid container spacing={4}>
            {data.chefs.map(person => (
              <Grid item key={person.totem} xs={12} md={6} lg={4}>
                <Person person={person}/>
              </Grid>
            ))}
          </Grid>
        </main>
  );
}
